<template>
  <el-drawer title="其他数据配置" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
    :modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
    <div class="h-full flex flex-dc" v-if="isDrawer">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">其他数据配置</div>
        <i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
      </div>
      <div class="flex-1 overflow-scroll-y">
        <t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="3" @handleEvent="handleEvent"
          class="drawer_form">
          <!-- 平台自定义插槽 -->

          <!-- 上传图片 -->
<!--          <template #uploadFile="data">
            <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
              iconName="el-icon-upload" iconSize="26" v-model="formOpts.formData[data.value]"></upload-file>
          </template> -->
        </t-simple-form>
      </div>
      <div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
        <el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
        <el-button type="primary" plain @click="handleClose">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'drawsDetails',
  components: {
    "TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  data () {
    return {
      dialogVisible: false,//确认展示套数，提示弹框
      goodId: '',//详情id
      isDrawer: false,
      butLoading: false,
      formOpts: {
        labelPosition: 'top',
        ref: null,
        formData: {
          // seriesCode: '',	//系列编码
          actyPath: '',	//微信活动模板地址
          actyTemId: '',	//微信活动模板id
          appId: '',	//小程序appid
          appSecret: '',	//小程序秘钥	
          blanceName: '',	//平台余额名称
          boxPath: '',	//新品上线抽盒路径
          bucketName: '',//七牛云仓库名
          goodPath: '',	//新品上线一番赏路径
          kdKey: '',	//快递100 Key
          kdSecret	: '',	//快递100 secret	
          kdSiId: '',	//快递100设备Id
          mallPath: '',	//新品上线精品商城路径	
          manageTemId: '',  //manageTemId	
        },
        fieldList: [
          // { label: '系列编号', value: 'seriesCode', comp: 'el-input' },
          { label: '微信活动模板地址', value: 'actyPath', comp: 'el-input' },
		  { label: '微信活动模板id', value: 'actyTemId', comp: 'el-input' },
		  { label: '小程序appid', value: 'appId', comp: 'el-input' },
		  { label: '小程序秘钥', vaule: 'appSecret', comp: 'el-input' },
		  { label: '平台余额名称', value: 'blanceName', comp: 'el-input' },
		  { label: '新品抽盒路径', value: 'boxPath', comp: 'el-input' },
		  { label: '七牛云仓库名', value: 'bucketName', comp: 'el-input' },
		  { label: '新品一番赏路径', value: 'goodPath', comp: 'el-input' },
		  { label: '快递100 Key', value: 'kdKey', comp: 'el-input' },
		  { label: '快递100 secret', value: 'kdSecret', comp: 'el-input' },
		  { label: '快递100设备Id', value: 'kdSiId', comp: 'el-input' },
		  { label: '新品精品商城路径', value: 'mallPath', comp: 'el-input' },
		  { label: '新品微信订阅模板id	', value: 'manageTemId', comp: 'el-input' },
        ],
        //按钮
        operatorList: [],
		listTypeInfo: {
		  shelves: this.$store.state.menu.dictList.shelves,
		  goodsMallTypes: this.$store.state.menu.dictList.goods_mall_type,
		}
      },
      testFlag: false, //测试
    }
  },
  created () {
    //测试人员
    // let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // if (userInfo.name == '测试') {
    //   this.testFlag = true
    // }
  },
  mounted () { },
  computed: {
    aCount () {
      const listA = this.formOpts.formData.detailsList.filter(item => { return item.levelType == 17 })
      return listA.length
    },
    bCount () {
      const listB = this.formOpts.formData.detailsList.filter(item => { return item.levelType == 18 })
      return listB.length
    },
    cCount () {
      const listC = this.formOpts.formData.detailsList.filter(item => { return item.levelType == 19 })
      return listC.length
    }
  },
  methods: {
    //取消展示套数的修改
    cancel () {
      this.dialogVisible = false
      this.butLoading = false
    },
    // 初始化
    init (id,type) {
      var that = this
      that.isDrawer = true
      that.goodId = id
	  if(type){
	  	this.formOpts.formData.sort = type[0].sort + 1
	  }
      if (id) {
        that.getDetails(id)
      }
      console.log(this.formOpts.formData)
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      this.resetForm()
    },
    //获取详情数据
    getDetails (id) {
      this.$http.get(`/restsConfig/findRestsConfigById?id=${id}`).then(({ data: res }) => {
        let goodData = res.data
        if (goodData.detailsList == null || goodData.detailsList == undefined || goodData.detailsList == "") {
          goodData.detailsList = []
        }
        this.formOpts.formData = goodData
      })
    },
    // 触发事件
    handleEvent (type, val) {
      switch (type) {
        case 'checkbox':
          console.log(val, type)
          break
        case 'showPage':
          if (!this.goodId) {
            this.formOpts.formData.totalPage = val
          }
          break
        case 'showPrice':
          this.formOpts.formData.integral = Number(val) * 2
      }
    },
    // 新增商品
    addDomain () {
      this.formOpts.formData.detailsList.push({
        ductName: '',
        price: '',
        levelType: '',
        boxImg: '',
      })
    },
    removeDomain (index) {
      this.formOpts.formData.detailsList.splice(index, 1)
    },
    editOkSubmit () {
      this.dialogVisible = false
      let goodData = JSON.parse(JSON.stringify(this.formOpts.formData))
      goodData.json = JSON.stringify(goodData.detailsList)
      delete goodData.detailsList
      // goodData.detailsList = JSON.stringify(goodData.detailsList)
      // console.log(goodData.detailsList, 'json')
      let apiUrl = ''
      if (goodData.id) {//修改
        apiUrl = '/restsConfig/editRestsConfig'
      } else {//新增
        apiUrl = '/restsConfig/addRestsConfig'
      }
      this.$http.post(apiUrl, goodData).then(res => {
        this.$message({
          message: goodData.id ? '修改成功' : '新增成功',
          type: 'success'
        })
        this.butLoading = false
        this.$emit('refresh')
        this.handleClose()
      }).catch(err => {
        this.butLoading = false
      })
    },
    // 提交form表单
    submitForm () {
      this.formOpts.ref.validate((valid) => {
        if (valid) {
          this.butLoading = true
          console.log(77, this.formOpts.formData)
          if (!valid) return
          console.log('最终数据', this.formOpts.formData)
          if (this.formOpts.formData.showPage > 10) {
            this.dialogVisible = true
            return
          } else {
            this.editOkSubmit()
          }
        }
      })
    },
    // 重置form表单
    resetForm () {
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data.call(this).formOpts.formData
      )
      this.$nextTick(() => {
        this.formOpts.ref.clearValidate()
      })
    },
  }
}
</script>

<style lang="less" scoped>
</style>